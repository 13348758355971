@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-black";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-bold";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-ligth";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato-Light.ttf") format("truetype");
}
/* center product steps product  */

.center-product{
    padding: 2rem;
}

/* alerts */
.aletr-auth-login {
    font-family: lato;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 1px;
}

/* nav bar menu */
.nav {
    background-color: #f7f7f7 !important;
    font-family: lato;
    font-style: normal;
}

.nav-a {
    color: #919BAA !important;
    font-size: 19px;
    letter-spacing: 1px;
    --bs-btn-padding-y: 0rem;
    border: none;
    margin-right: .4rem !important;
    margin-left: .4rem !important;
}

.nav-a-btn {
    font-size: 20px;
    letter-spacing: 1px;
    --bs-btn-padding-y: 0rem;
    border: none;
}

.nav-a:hover {
    background: #0063D1;
    color: #ffffff !important;
    --bs-btn-padding-y: 0rem !important;
}

.nav-a:active {
    background: #0063D1 !important;
    color: #ffffff !important;
    --bs-btn-padding-y: 0rem !important;
}

.nav-button {
    background-color: #0063D1;
    color: #ffffff;
    width: 160px;
    height: 25px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    font-size: 20px;
}

.nav-logo {
    width: 7rem;
    height: 6rem;
}

.nav-center {
    display: flex;
    /*     width: 75%; */
    justify-content: center;
    margin: 0 auto;
}

.nav-cart {
    --bs-btn-border-width: 0px: --bs-btn-border-width: 0px !important;
}

.btn-cart {
    color: #0063D1 !important;
    background-color: #ffffff;
    border-color: #003c85 !important;
    padding: 0;
    border: none;
    background: none;
}

.btn-cart:hover {
    color: #0063D1 !important;
    background-color: transparent;
    --bs-btn-border-width: 0px !important;
    z-index: 0;
}

.btn-cart:active {
    color: transparent !important;
    background-color: transparent;
    --bs-btn-border-width: 0px !important;
    z-index: 0;
}

.btn-cart img {
    width: 30px;
}
.btn-user {
    color: #0063D1 !important;
    background-color: #ffffff;
    border-color: #003c85 !important;
    padding: 0;
    border: none;
    background: none;
}
.btn-user:hover {
    color: #0063D1 !important;
    background-color: transparent;
    --bs-btn-border-width: 0px !important;
    z-index: 0;
}

.btn-user:active {
    color: transparent !important;
    background-color: transparent;
    --bs-btn-border-width: 0px !important;
    z-index: 0;
}
.btn-user img {
    width: 45px;
}


.btn-cart .cart-badge {
    border: 2px solid rgb(255, 255, 255);
    font-family: lato;
    font-size: 9px;
    width: 2rem;
    height: 1.1rem;
    color: #ffffff !important;
    background-color: #0063D1;
}

.dropdown-cart {
    box-shadow: 0px 0px 10px 0.1px #ccc;
    padding: 1rem;
    font-family: lato;
    --bs-dropdown-min-width: 26rem !important;
}
.dropdown-profile {
    box-shadow: 0px 0px 10px 0.1px #ccc;
    padding: 1rem;
    font-family: lato;
    --bs-dropdown-min-width: 12rem !important;
}
.dropdown-profile  a{
   font-size: 0.8rem;
   text-decoration: none;
}

.dropdown-cart::after {
    content: '';
    position: absolute;
    top: -35px;
    left: 90%;
    transform: translateX(-50%);
    border: 25px solid transparent;
    border-bottom: 20px solid #ffffff;
}

.dropdown-cart img {
    width: 50px;
}

.dropdown-li {
    font-size: 0.9rem;
}

.dropdown-li p {
    font-size: 0.8rem;
}

.dropdown-cart .color-price {
    color: #0063D1;
}

.dropdown-cart .btn-view-cart {
    background: #0063D1;
    color: #ffffff;
    border-radius: 0%;
    font-family: lato-bold;
    --bs-btn-padding-y: 0.2rem;
}

.btn-login-nav {
    width: 40px;
}

/* end nav bar */

/* home banner  */
.home {
    background-image: url("../images/products/background-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 5.5rem;
    padding-bottom: 5rem;
}

.home h2 {
    font-family: Lato-ligth;
    font-style: normal;
    color: #606060;
}

.home p {
    font-family: lato;
    color: #606060;
    font-size: 20px;
}

.home h1 {
    font-family: lato-bold;
    font-style: normal;
    color: #606060;
}

.banner-img {
    width: 90%;
    height: 90%;
}

.home-cart-badge {
    font-size: 0.8rem;
}

.btn-banner {
    background-color: #0063D1;
    color: #ffffff;
    font-family: lato;
    font-style: normal;
    font-size: 16px;
    width: 10rem;
    border-radius: 10px !important;
}

.btn-banner:hover {
    background-color: brown;
}

.banner-social {
    float: right;
}

.banner-social img {
    position: fixed;
}

/* end banner home */

/* grid cards products  */
.background-banner-grid-product {
    background-image: url("../images/products/background-select-product.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 4.5rem;
    padding-bottom: 5rem;
}

.background-banner-grid-product h1 {
    font-family: lato;
    color: #0063D1;
}

.cards {
    border: none !important;
    background-image: url("../images/products/background-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 12em;
}

.cards h6 {
    color: #616161;
    font-family: lato;
    font-style: normal;
    line-height: 0.2;
}

.cards h4 {
    color: #0063D1;
    font-family: lato;
    font-style: normal;
}

.cards .btn-grid {
    background-color: #bebebf;
    color: #ffffff;
    font-family: lato-black;
    font-style: normal;
    font-size: 13px;
    border-radius: 0px !important;
}

.cards .btn-grid:hover {
    background-color: #0063D1;
    color: #ffffff;
}

.cards .img-product {
    width: 220;
    height: 12em;
}

/* end grid product */

/* step product */
.accordion-container {
    box-shadow: 0px 0px 20px 0.5px #ccc;
}

.step-product {
    font-family: lato;
    font-style: normal;
}

.step-product h1 {
    font-family: lato;
    color: #0063D1;
}

.step-product-backgound {
    background-image: url("../images/products/background-select-product.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 3rem;
}

.overflow-steps {
    height: 40rem;
}

.step-header-acordeon {
    display: flex;
}

.step-header-acordeon-title {
    font-family: Lato-black;
    background: #0063D1 !important;
    color: #fff;
    border-radius: opx !important;
}

.step-header-acordeon-subtitle {
    font-family: lato;
    border-radius: opx !important;
    color: #848381;
    background: #eeede8;
}

.step-header-acordeon-subtitle button {
    background: #eeede8;
}

.step-header-acordeon-subtitle button {
    --bs-accordion-btn-padding-y: 0.5rem;
}

.image-product-img {
    width: 20%;
}

.step-price {
    background: #0063D1 !important;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
    font-size: 0.9rem;
    bottom: 0;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.step-price-flex {
    display: flex;
}

.step-price p {
    color: #ffffff;
    padding-top: 0.3rem;
}

.step-price button {
    font-size: 0.8rem;
    background: #003c85;
    color: #ffffff;
    height: 2rem;
    --bs-btn-padding-x: 0.905rem;
    --bs-btn-padding-y: 0rem;
}

.accordion-button:not(.collapsed) {
    color: #003c85 !important;
}

.accordion-button:focus {
    border-color: #ffffff !important;
    box-shadow: none;
}

.step-description {
    background: #f8f8f8;
    padding: 2rem;
}

/* .step-acordeon-title{
    background: #0063D1 !important;
    color: #fff;
} */

/* end step product */
.login {
    background: #F5F5F5;
    padding: 1.5rem 1rem 5rem 1rem !important;
}

.login-title {
    font-family: lato-bold;
    font-size: 17px;
    letter-spacing: 1px;
    color: #606060;
}

.login-form {
    background: #ffffff;
    padding: 2rem;
}

.login input {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 2.2rem !important;
}
.login .create-account{
    padding: 4rem;
}
.login .create input {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 0rem !important;
}
.login .create label {
    font-family: lato-bold;
    font-size: 14px;
    letter-spacing: 1px;
    color: #606060;
}
.login-create-help{
    font-family: lato-bold;
    font-size: 11px;
    letter-spacing: 1px;
    color: #606060;
}

.login .input-border-left {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 2.2rem !important;
    border-right: 0px;
}

.login .btn-show {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 2.2rem !important;
    border-left: 0px;
}

.login .btn-show img {
    width: 20px;
}

.login input::placeholder {
    font-family: lato !important;
    color: #cccccc;
    font-size: 13px;
    letter-spacing: 1px;
}

.login button {
    border-radius: 0px;
}

.btn-login {
    background: #0063D1;
    color: #ffffff;
    font-family: lato-bold;
    font-size: 17px;
    letter-spacing: 1px;
}

.btn-login:hover {
    background: #036ee9;
    color: #ffffff;
}

.forgot-password {
    display: flex;
    font-size: 15px;
    color: #0063D1;
    font-family: lato !important;
    letter-spacing: 1px;
}

.btn-singin {
    color: #606060;
    font-size: 13px;
    letter-spacing: 1px;
    background: #F5F5F5;
    padding: 0.2rem 0.5rem 0.2rem 0.2rem;
}

.btn-singin:hover {
    color: #606060;
    background: #e4e3e3;

}

.btn-social-singin {
    display: flex;
}

.btn-social-singin img {
    width: 20px;
}

.login .btn-sign {
    color: #0063D1;
    padding: 2rem;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
}

.login .privicy-policy {
    color: #999999;
    padding: 1rem;
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
}

.login .privicy-policy a:link {
    text-decoration: none;
}

.modal-login {
    --bs-modal-border-radius: 0rem;
    --bs-modal-width: 400px;
}

.modal-login .content {
    padding: 1rem;
}

.modal-login .footer-login-modal {
    border-top: none !important;
}

.modal-login .btn-cancel {
    background: white;
    border-color: black;
    color: black;
    font-family: lato;
    font-size: 18px;
    letter-spacing: 1px;
    --bs-btn-border-radius: 0rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 2.25rem;
}

.modal-login .btn-send {
    background: #0063D1;
    color: white;
    font-family: lato;
    font-size: 18px;
    letter-spacing: 1px;
    --bs-btn-border-radius: 0rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 2.25rem;
}

.modal-login p {
    font-family: lato;
    font-size: 15px;
    letter-spacing: 1px;
}

.verify-code {
    background: #F5F5F5;
}

.verify-code .row-down {
    padding-block-end: 5rem;
}

.verify-code .enter-verification-code {
    padding: 3rem;
    background: #fff;
}

.verify-code .btn-step {
    background: #0063D1;
    color: white;
    font-family: lato;
    font-size: 14px;
}

.verify-code .subtitle {
    font-size: 14px;
    font-family: lato;
    color: #606060;
}


.verify-code .enter-verification-code img {
    width: 20px;
}

.verify-code .input-code {
    font-size: 18px;
    width: 35px !important;
    padding: 0.5rem 0.3rem;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0rem;
    border-color: #4D4D4D;

}

.verify-code .input-code:focus {
    box-shadow: 0 0 0 0;
}

.verify-code .btn-verify {
    width: 78%;
    font-family: lato-bold;
    letter-spacing: 1px;
    background: #0063D1;
    border-radius: 0px;
}

.arrow-line {
    width: 300px;
    height: 1px;
    border-top: 1px solid black;
    position: relative;
}

.arrow-line::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
    transform: rotate(45deg);
}

/* auth */

/* cart product */
.cart {
    background: #F5F5F5;
}

.cart-bg-2 {
    background: #F5F5F5;
}

.cart .header {
    background-image: url("../images/products/background-select-product.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.cart .header h1 {
    color: #0063D1;
    font-family: lato;
    font-size: 3rem;
}

.cart .cart-content {
    background: white;
    border-radius: 3px;
    font-size: 16px;
    font-family: lato;
    letter-spacing: 1px;
    color: #606060;
}

.cart .input-cart-check {
    width: 20px;
    height: 20px;
}

.cart .img-shop-home {
    width: 23px;
    height: 25px;
}

.cart .content-product {
    border-top: 1px solid #E5E5DF;
}

.cart .detail-product {
    padding: 1rem;

}

.cart .img-product-cart {
    width: 50%;
}

.cart .content-product-p {
    font-size: 13px;
    font-family: lato;
    letter-spacing: 1px;
    width: 20%;
}

.cart .variatios-product {
    font-size: 13px;
    font-family: lato;
    letter-spacing: 1px;
}

.cart .price-product {
    font-size: 13px;
    font-family: lato;
    letter-spacing: 1px;

}

.cart .price-product-color {
    font-size: 13px;
    font-family: lato;
    letter-spacing: 1px;
    color: #0063D1;
}

.cart .spinner {
    font-family: lato;
    color: #B3B3B3;
    width: 80%;
}

.cart .btn-spinner {
    font-family: lato;
    color: #B3B3B3;
    border-color: #B3B3B3;
    border-radius: 0px;
    font-size: 1rem;
    width: 15%;
    height: 15%;
}

.cart .btn-spinner:hover {
    font-family: lato;
    color: #B3B3B3;
    border-color: #B3B3B3;
    border-radius: 0px;
}

.cart .spinner i {
    font-family: lato;
    color: #B3B3B3;
}

.cart .spinner i:hover {
    color: #B3B3B3;
}

.cart .btn-crash-product {
    width: 30px;
}

.cart .cart-footer {
    border-top: 1px solid #E5E5DF;
    font-size: 13;
    padding-top: 1rem;
}

.cart .padding-ckech-out {
    padding: 2rem;
}

.cart .padding-content-check-out {
    padding: 2rem;
}

.cart .check-table table tbody tr th{
    border-style: solid !important;
    border-bottom-width: 0px;
}

.cart .cart-content .btn-checkout {
    background: #0063D1;
    color: #ffffff;
    font-family: lato-bold;
    font-size: 17px;
    letter-spacing: 1px;
    border-radius: 0px;
}

/* end cart */

/* profile */
.profile{
    background: #F5F5F5;
}

.profile-bg-2 {
    background: #F5F5F5;
}
.profile-form {
    background: #ffffff;
    padding: 2rem;
}

.profile input {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 0.2rem !important;
}
.profile textarea {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 0.2rem !important;
}
.profile select {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 0.2rem !important;
}
.profile .create-account{
    padding: 4rem;
}
.profile .create input {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 0rem !important;
}
.profile .create label {
    font-family: lato-bold;
    font-size: 14px;
    letter-spacing: 1px;
    color: #606060;
}
.profile-create-help{
    font-family: lato-bold;
    font-size: 11px;
    letter-spacing: 1px;
    color: #606060;
}

.profile .input-border-left {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 2.2rem !important;
    border-right: 0px;
}

.profile .btn-show {
    border-color: #606060;
    border-radius: 0px !important;
    margin-top: 2.2rem !important;
    border-left: 0px;
}

.profile .btn-show img {
    width: 20px;
}

.profile input::placeholder {
    font-family: lato !important;
    color: #cccccc;
    font-size: 13px;
    letter-spacing: 1px;
}

.profile button {
    border-radius: 0px;
}

.btn-profile {
    background: #0063D1;
    color: #ffffff;
    font-family: lato-bold;
    font-size: 17px;
    letter-spacing: 1px;
}

.btn-profile:hover {
    background: #036ee9;
    color: #ffffff;
}

.profile .header {
    background-image: url("../images/products/background-select-product.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.profile .header h1 {
    color: #0063D1;
    font-family: lato;
    font-size: 3rem;
}

.profile .profile-content {
    background: white;
    border-radius: 3px;
    font-size: 16px;
    font-family: lato;
    letter-spacing: 1px;
    color: #606060;
    padding: 4rem;
}
.profile .profile-forms{

}

/* end profile */

/* footer start */
.footer-background-top {
    background-image: url("../images/footer/background-footer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.footer {
    position: relative;
    width: 100%;
    background-color: #0f3a65;
    color: white;
    text-align: center;
}

.footer .footer-nav {
    color: white !important;
    font-family: lato;
    font-style: normal;
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-top: 1rem;
}

.footer .footer-nav-item {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
}

.footer-img {
    height: 50%;
}

.footer p {
    color: #f7f7f7 !important;
    font-family: lato;
    font-size: 15px;
    font-style: normal;
    padding-top: 1.35rem;
    letter-spacing: 1px;
}

.footer-p-image {
    text-align: left;
}

.footer-auth {
    font-size: lato;
}

.footer-column {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.footer-auth .row-footer .title {
    font-family: Lato-black;
    color: #606060;
    letter-spacing: 1px;
}

.footer-auth .row-footer ul li a {
    font-family: lato;
    color: #606060;
    font-size: 14px;
    letter-spacing: 1px;
}

.footer-auth .row-footer ul li a img {
    width: 20px;
}